import { Image } from "./image";

export const Cinematography = (props) => {
  return (
    <div id='cinematography' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Cinematography</h2>
          <p>
          </p>
        </div>
        <div className='row'>

<iframe width="560" height="315" src="https://www.youtube.com/embed/0H-ZE2KkWL8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


        </div>
      </div>
    </div>
  )
}
